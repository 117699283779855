import * as React from 'react';
import styled from 'styled-components';
import { Link, graphql, StaticQuery } from 'gatsby';
import LogoHorizontal from './icons/LogoHorizontal';
import theme from '../styles/theme';

export interface IFooterMenuProps {
  config: {
    footerMenu: Array<{
      title: string;
      url: string;
    }>;
  };
}

const Wrapper = styled.footer`
  padding: 2rem 1rem 5rem;
  max-width: ${theme.containerWidth};
  margin: 0 auto;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  text-align: center;
  span {
    max-width: 100%;
  }
  p {
    max-width: none;
    margin: 0.75rem 0 0;
    font-size: 0.9em;
    :last-of-type {
      margin: 1rem auto 0;
      padding-top: 0.35rem;
      font-size: 0.85em;
      opacity: 0.9;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      width: 50%;
    }
  }
`;

const LinksWrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
  a {
    text-decoration: none;
    font-weight: 500;
    margin: 1rem;
    :hover {
      opacity: 0.9;
    }
  }
`;

const FooterMenu: React.SFC<IFooterMenuProps> = props => {
  return (
    <LinksWrapper>
      {props.config.footerMenu.map(menu => {
        if (menu.url.includes('mailto:')) {
          return (
            <a key={menu.url} href={menu.url}>
              {menu.title}
            </a>
          );
        }
        return (
          <Link key={menu.url} to={menu.url}>
            {menu.title}
          </Link>
        );
      })}
    </LinksWrapper>
  );
};

export const Footer: React.SFC<{}> = () => {
  const date = new Date();
  return (
    <Wrapper>
      <LinksWrapper>
        <StaticQuery
          query={graphql`
            query footerQuery {
              config: sanityConfig {
                footerMenu {
                  title
                  url
                }
              }
            }
          `}
          render={FooterMenu}
        />
      </LinksWrapper>
      <Link to="/">
        <LogoHorizontal color="#fff" width="350px" />
      </Link>
      <p>
        ©2018-{date.getFullYear()} - Data Hackers, Todos os direitos reservados.
      </p>
      <p>
        Site por{' '}
        <a
          href="https://kaordica.design"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kaordica
        </a>{' '}
        | Mantido por{' '}
        <a
          href="https://www.gabrielpaiva.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gabriel Paiva
        </a>
      </p>
    </Wrapper>
  );
};

Footer.displayName = 'Footer';

export default Footer;
