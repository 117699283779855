import * as React from 'react';

import Footer from '../components/Footer';
import GlobalStyles from '../styles/global';
import HeaderTemplate from '../components/Header/HeaderTemplate';
import FallbackPageMeta from '../components/FallbackPageMeta';

export interface IBasicLayoutProps {
  shouldStartTransparent?: boolean;
}

export const BasicLayout: React.SFC<IBasicLayoutProps> = props => {
  return (
    <>
      <GlobalStyles />
      <FallbackPageMeta />
      <HeaderTemplate shouldStartTransparent={props.shouldStartTransparent} />
      <main style={{ padding: '0 1rem' }}>{props.children}</main>
      <Footer />
    </>
  );
};

BasicLayout.displayName = 'BasicLayout';

export default BasicLayout;
