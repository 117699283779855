import theme from '../theme';

export const baseStyles = `
  *, *::before, *::after {
    box-sizing: inherit;
    font-family: inherit;
  }

  *::-webkit-scrollbar-thumb {
    background: ${theme.colors.primaryLight};
  }

  html {
    box-sizing: border-box;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    background: ${theme.colors.bg};
  }

  a {
    color: ${theme.colors.text};
  }

  img {
    max-width: 100%
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 1rem;
  }

  b {
    font-weight: 500;
  }
`;

export default baseStyles;
