import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import theme from '../../styles/theme';
import { IFileQueryData } from '../../utils/types';
import { medias } from '../../styles/helpers/mediaQueries';

const StyledWrapper = styled.div`
  max-width: ${theme.containerWidth};
  margin: 0 auto 4rem;
  padding: 5rem 0 0;
  h1 {
    margin: 2.5rem 0 3rem;
    max-width: 750px;
  }
  h1 ~ p {
    margin: -2.5rem 0 3rem;
    max-width: 750px;
  }
`;

const spinAnimation = keyframes`
  from {
    transform: translate(50%, -50%) rotate(0);
  } to {
    transform: translate(50%, -50%) rotate(360deg);
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  overflow: hidden;
  img {
    object-fit: contain !important;
  }
  .gatsby-image-wrapper {
    height: 135%;
    overflow: visible !important;
    transform: translate(50%, -50%);
    width: 140vw;
    height: 140vh;
    opacity: 0.4;
    z-index: -1;
    animation: 520s ${spinAnimation} cubic-bezier(0.25, -0.64, 0.55, 1.84)
      infinite;
    > div {
      display: none;
    }
  }
`;

export const TitleWrapper: React.SFC<{}> = ({ children }) => (
  <StyledWrapper>
    <StaticQuery
      query={graphql`
        query titleWrapperQuery {
          file(relativePath: { eq: "fundo-hero.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={({ file }: IFileQueryData) => (
        <ImageWrapper aria-hidden={true} tabIndex={-1}>
          <GatsbyImage fluid={file.childImageSharp.fluid} />
        </ImageWrapper>
      )}
    />
    {children}
  </StyledWrapper>
);

export const PostWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: ${theme.lgContainerWidth};
  margin: 0 auto;
  @media ${medias.tabletMin} {
    aside {
      margin: 0 1.25rem;

      flex-basis: 100%;
    }
  }
`;

export const ListErrorMessage = styled.p`
  grid-column: 1 / -1;
  margin: 1rem auto 3rem;
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
`;
