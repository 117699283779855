import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import LogoHorizontal from '../icons/LogoHorizontal';
import theme from '../../styles/theme';
import { hexToRgb } from '../../styles/helpers/hexToRgb';
import { medias } from '../../styles/helpers/mediaQueries';
import LogoIcon from '../icons/LogoIcon';

export interface IHeaderTemplateProps {
  shouldStartTransparent?: boolean;
}

export interface IHeaderTemplateState {
  isTransparent: boolean;
}

const Wrapper = styled.header`
  z-index: 51;
  position: fixed;
  left: 0;
  top: 0;
  margin: 0 auto;
  padding: 0.75rem 0.5rem;
  background: ${hexToRgb(theme.colors.darkPurple, 0.7)};
  width: 100%;
  transition: ${theme.transitions.default} opacity;
  opacity: ${({ isTransparent }: { isTransparent: boolean }) =>
    isTransparent ? 0 : 1};
`;

const Container = styled.div`
  max-width: ${theme.lgContainerWidth};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${medias.largeMax} {
    font-size: 0.8rem;
    span:last-of-type {
      display: none;
    }
  }
  @media ${medias.largeMin} {
    span:first-of-type {
      display: none;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  a {
    font-weight: 300;
    font-size: 0.9em;
    text-decoration: none;
    color: white;
    margin: 0 1em;
    transition: ${theme.transitions.default};
    :hover {
      color: ${theme.colors.primaryLight};
    }
    text-transform: uppercase;
    letter-spacing: 0.1em;
    @media ${medias.phoneMax} {
      margin: 0 0.5em;
    }
  }
`;

export class HeaderTemplate extends React.Component<
  IHeaderTemplateProps,
  IHeaderTemplateState
> {
  constructor(props: IHeaderTemplateProps) {
    super(props);
    this.state = {
      isTransparent: props.shouldStartTransparent ? true : false,
    };
  }

  toggleTransparency = () => {
    if (window.scrollY > 50) {
      this.setState({ isTransparent: false });
      window.removeEventListener('scroll', this.toggleTransparency);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleTransparency);
  }

  componentDidMount() {
    if (this.props.shouldStartTransparent) {
      window.addEventListener('scroll', this.toggleTransparency);
    }
  }

  render() {
    return (
      <Wrapper isTransparent={this.state.isTransparent}>
        <Container>
          <Link to="/">
            <LogoIcon width="2em" />
            <LogoHorizontal width="8em" />
          </Link>
          <Nav>
            <Link to="/blog">Blog</Link>
            <a href="https://www.datahackers.news/">Newsletter</a>
            <Link to="/podcast">Podcast</Link>
            <Link to="/slack">Slack</Link>
          </Nav>
        </Container>
      </Wrapper>
    );
  }
}

export default HeaderTemplate;
